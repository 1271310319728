import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Alert, AlertTitle} from "@mui/material";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import {NumericFormat} from 'react-number-format';
import {useEffect, useState} from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PopupWindow from "../PopupWIndow";
import Button from '@mui/material/Button';
import {styled} from '@mui/system';
import ServiceRecoveryImg from '../../assets/images/ServiceRecovery_ListenOwnMakeItRight_01.png';
import config from "../../config/config";
import CircularProgress from "@mui/material/CircularProgress";
import {createRiseUp} from "../../Object/RiseUp";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    border: 'none !important',
    boxShadow: 'none',
}));


const StyledButton = styled(Button)({
    borderColor: '#F16F42',
    border: '2px solid',
    color: '#F16F42',
    '&:hover': {
        backgroundColor: '#F16F42',
        borderColor: '#F16F42',
        border: '2px solid',
        color: 'white',
    },
    '&:active': {
        backgroundColor: '#F16F42',
        borderColor: '#F16F42',
        border: '2px solid',
        color: 'white',
    },
});
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const {onChange, ...other} = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="$"
        />
    );
});
export default function ServiceRecorveryForm({accountInfo, employeeList}) {
    // const [employeeList, setEmployeeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        noFollowUp: false,
        followUp: false,
    });
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [riseUp, setRiseUp] = React.useState(createRiseUp());
    const authToken = "Bearer " + accountInfo.jwtIdToken;
    const handleSwitchChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.checked,
        });
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleEmployeeSelect = (event, newValue) => {
        setEmployees(newValue);
    };
    const handleRadioChange = (event) => {
        const value = event.target.value;
        setRiseUp((prevObject) => ({
            ...prevObject,
            recipientType: value,
        }));
    };

    useEffect(() => {
        if (loading) {
            const submitData = async () => {
                try {
                    const response = await fetch(config.BAST_URL + "riseup/submit", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `${authToken}`
                        },

                        body: JSON.stringify(riseUp)
                    });

                    if (response.ok) {
                        console.log('Success:', response);
                        setLoading(false);
                        handleOpen();
                    } else {
                        setLoading(false);
                        console.error('Error:', response);
                        setShowAlert(true);
                        setErrorMessage('Error while submitting Service Recovery: ' + response.status + ' ' + response.statusText);
                    }
                } catch (error) {
                    setLoading(false);
                    setShowAlert(true);
                    setErrorMessage('Error while submitting Service Recovery: ' + error);
                    console.error('There was an error!', error);
                }
            };

            submitData();
        }
    }, [loading, riseUp]);

    const submitForm = async (e) => {
        e.preventDefault();
            setLoading(true);
            // accountInfo.account.userName
            let createdBy = employeeList.filter(employee => employee.email.trim().toLowerCase() === accountInfo.account.userName.toLowerCase())[0];
            let officer = employeeList.filter(employee => employee.employeeName.trim() === employees[0])[0];
            setRiseUp(prevState => ({
                ...prevState,
                riseupType: 'ServiceRecovery',
                accountOfficer: employees.join("\n"),
                officerFollowUpRequired: formData.followUp,
                noFollowUpRequired: formData.noFollowUp,
                createdBy: accountInfo.account.name, //accountInfo.account.name
                createdByEmail: accountInfo.account.userName, //accountInfo.account.userName
                nominatorLocation: createdBy ? createdBy.location : '',
                department: officer ? officer.department : '',
                location: officer ? officer.location : ''
            }));
            console.log(riseUp);

    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <PopupWindow open={open} handleClose={handleClose} formType={"service-recovery"}/>
            {showAlert && (
                <Alert severity="error" onClose={() => {setShowAlert(false);}}>
                    <AlertTitle>Something went wrong</AlertTitle>
                    {errorMessage}
                </Alert>
            )}
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '30%',
                    marginRight: '5%'
                }}>
                    <Box
                        component="img"
                        sx={{marginTop: '8%'}}
                        alt="Logo"
                        src={ServiceRecoveryImg}
                    />
                    <Typography variant="h5" gutterBottom sx={{color: "#00b4db", fontFamily: 'Segoe UI', fontWeight: 'semibold',marginTop: '10%'}}>
                        Track that thoughtful
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{color: "#00b4db", fontFamily: 'Segoe UI', fontWeight: 'semibold'}}>
                        Service Recovery
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{color: "#00b4db", fontFamily: 'Segoe UI', fontWeight: 'semibold'}}>
                        you just owned.
                    </Typography>
                </div>
                <Stack spacing={1} sx={{width: '70%',}}>
                    <Item>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: ''
                        }}>
                            <Typography variant="subtitle2" gutterBottom sx={{
                                fontFamily: 'Segoe UI',
                                fontWeight: 'bold',
                                color: "#F37043",
                                marginLeft: '8px'
                            }}>
                                RELATIONSHIP MANAGER
                            </Typography>
                            <Autocomplete
                                multiple
                                disablePortal
                                id="employee-select"
                                options={employeeList.map((option) => option.employeeName)}
                                sx={{width: '350px'}}
                                value={employees}
                                size="small"
                                onChange={handleEmployeeSelect}
                                renderInput={(params) => <TextField {...params} label="Find team members"/>}
                            />
                        </div>
                    </Item>
                    <Item>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: ''
                        }}>
                            <Typography variant="subtitle2" gutterBottom sx={{
                                fontFamily: 'Segoe UI',
                                fontWeight: 'bold',
                                color: "#F37043",
                                marginLeft: '8px'
                            }}>
                                CUSTOMER ACCOUNT
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                size="small"
                                sx={{width: '350px'}}
                                value={riseUp.customerAccount}
                                onChange={(e) => setRiseUp({ ...riseUp, customerAccount: e.target.value })}
                            />
                        </div>

                    </Item>
                    <Item>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: ''
                        }}>
                            <Typography variant="subtitle2" gutterBottom sx={{
                                fontFamily: 'Segoe UI',
                                fontWeight: 'bold',
                                color: "#F37043",
                                marginLeft: '8px'
                            }}>
                                RECIPIENT NAME
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                size="small"
                                sx={{width: '350px'}}
                                value={riseUp.recipientName}
                                onChange={(e) => setRiseUp({ ...riseUp, recipientName: e.target.value })}
                            />
                        </div>

                    </Item>
                    <Item>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',}}>
                            <Typography variant="subtitle2" gutterBottom sx={{
                                fontFamily: 'Segoe UI',
                                fontWeight: 'bold',
                                color: "#F37043",
                                marginLeft: '8px',
                                marginRight: '235px'
                            }}>
                                RECIPIENT TYPE
                            </Typography>
                            <FormControl>
                                {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={riseUp.recipientType}
                                    onChange={handleRadioChange}
                                >
                                    <FormControlLabel value="Customer" control={<Radio size="small"/>}
                                                      label="Customer"/>
                                    <FormControlLabel value="Future Customer" control={<Radio size="small"/>}
                                                      label="Future Customer"/>
                                    <FormControlLabel value="Team Member" control={<Radio size="small"/>}
                                                      label="Team Member"/>
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Item>
                    <Item>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: ''
                        }}>
                            <Typography variant="subtitle2" gutterBottom sx={{
                                fontFamily: 'Segoe UI',
                                fontWeight: 'bold',
                                color: "#F37043",
                                marginLeft: '8px'
                            }}>
                                SITUATION/DISCOVERY
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                label=""
                                multiline
                                rows={3}
                                sx={{width: '350px'}}
                                value={riseUp.situation}
                                onChange={(e) => setRiseUp({ ...riseUp, situation: e.target.value })}
                            />
                        </div>

                    </Item>
                    <Item>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: ''
                        }}>
                            <Typography variant="subtitle2" gutterBottom sx={{
                                fontFamily: 'Segoe UI',
                                fontWeight: 'bold',
                                color: "#F37043",
                                marginLeft: '8px'
                            }}>
                                RECOVERY
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                label=""
                                multiline
                                rows={3}
                                sx={{width: '350px'}}
                                value={riseUp.experience}
                                onChange={(e) => setRiseUp({ ...riseUp, experience: e.target.value })}
                            />
                        </div>
                    </Item>
                    <Item>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: ''
                        }}>
                            <Typography variant="subtitle2" gutterBottom sx={{
                                fontFamily: 'Segoe UI',
                                fontWeight: 'bold',
                                color: "#F37043",
                                marginLeft: '8px'
                            }}>
                                GIFT AMOUNT (if applicable)
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                sx={{width: '350px'}}
                                size="small"
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                }}
                                value={riseUp.giftAmount}
                                onChange={(e) => setRiseUp({ ...riseUp, giftAmount: e.target.value })}
                            />
                        </div>
                    </Item>
                    <Item>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: ''
                        }}>
                            <Typography variant="subtitle2" gutterBottom sx={{
                                fontFamily: 'Segoe UI',
                                fontWeight: 'bold',
                                color: "#F37043",
                                marginLeft: '8px'
                            }}>
                                FOLLOW UP
                            </Typography>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch
                                        size="small"
                                        checked={formData.noFollowUp}
                                        onChange={handleSwitchChange}
                                        name="noFollowUp"
                                        disabled={formData.followUp}
                                    />}
                                                  label="No follow up required"/>
                                <FormControlLabel control={
                                    <Switch
                                        size="small"
                                        checked={formData.followUp}
                                        onChange={handleSwitchChange}
                                        name="followUp"
                                        disabled={formData.noFollowUp}
                                    />}
                                                  label="Relationship Manager follow up required"/>
                            </FormGroup>
                        </div>
                    </Item>
                </Stack>
            </div>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
                <StyledButton variant="outlined" onClick={submitForm}
                              sx={{fontFamily: 'Segoe UI', fontWeight: 'bold'}}>
                    Submit Service Recovery
                </StyledButton>
            </Box>
        </React.Fragment>
    );
}