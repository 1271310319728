import {isDate} from "date-fns";
import dayjs from 'dayjs';
const convertFiltersToString = (filter) => {
    console.log(filter);
    let { field, operator, value } = filter;
    if (isDate(value)) {
        value = dayjs(value).add(1, 'day').format('YYYY-MM-DD');

        console.log(value);
    } else {
        if (value.includes(",") || value.includes(":")) {
            value = `"${value}"`;
        }
    }

    switch (operator) {
        case 'contains':
            return `${field}:*${value}*`
        case  'startsWith':
            return `${field}:${value}*`
        case  'endsWith':
            return `${field}:*${value}`
        case  'equals':
            return `${field}=${value}`
        case  '=':
            return `${field}=${value}`
        case  '<':
            return `${field}<${value}`
        case  '>':
            return `${field}>${value}`
        case  '<=':
            return `${field}<=${value}`
        case  '>=':
            return `${field}>=${value}`
        case 'is':
            return `${field}=${value}`
        case 'before':
            return `${field}<${value}`
        case 'after':
            return `${field}>${value}`
        case 'onOrBefore':
            return `${field}<=${value}`
        case 'onOrAfter':
            return `${field}>=${value}`
        default:
            return `${field}=${value}`
    }

};

const getFilterString = ( filters ) => {
    return filters.map(convertFiltersToString).join(',');
};

export default getFilterString;