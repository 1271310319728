import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import riseUpColorBar from '../../assets/images/rise-up-color-bar.jpg';
import valueLogo from '../../assets/images/the-vault-logo.png';
import Box from '@mui/material/Box';
import Link from "@mui/material/Link";

function Header(props) {

    return (
        <React.Fragment>
            <AppBar position="static" elevation={0} sx={{backgroundColor: '#fff', zIndex: 1301}}>
                <Toolbar>
                    <Link href="https://choicefinancial.sharepoint.com/sites/TheVault" target="_blank"
                          sx={{color: "white", fontFamily: 'Segoe UI', fontWeight: '600', fontSize: '12px'}}>
                        <Box
                            component="img"
                            sx={{height: 55}}
                            alt="Logo"
                            src={valueLogo}
                        />

                    </Link>



                </Toolbar>
            </AppBar>
            <AppBar
                position="static"
                elevation={0}
                sx={{
                    height: 38,
                    backgroundImage: `url(${riseUpColorBar})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 1301
                }}
            >
                <Toolbar>

                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}


export default Header;