import * as React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import PremierExperienceForm from "../PremierExperienceForm";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import {AppBar} from "@mui/material";
import RiseUpForm from '../RiseUpForm';
import miniRiseUpLogo from '../../assets/images/rise-up-logo-mini.png';
import workbookLogo from '../../assets/images/rise-up-workbook-logo.png';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import {useTheme} from '@mui/material/styles';
import riseUpGif from '../../assets/images/ChoiceCulture_EffectiveRecogntion_Step_Animation.gif';
import './styles.css';
import {Button, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import Header from '../Header'
import ServiceRecorveryForm from "../ServiceRecorvery";
import Link from "@mui/material/Link";
import Forms from "../Forms";


// const AppBar = styled(MuiAppBar, {
//     shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(['width', 'margin'], {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//     }),
//     ...(open && {
//         marginLeft: drawerWidth,
//         width: `calc(100% - ${drawerWidth}px)`,
//         transition: theme.transitions.create(['width', 'margin'], {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//     }),
// }));

// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//     ({ theme, open }) => ({
//         '& .MuiDrawer-paper': {
//             position: 'relative',
//             whiteSpace: 'nowrap',
//             width: drawerWidth,
//             transition: theme.transitions.create('width', {
//                 easing: theme.transitions.easing.sharp,
//                 duration: theme.transitions.duration.enteringScreen,
//             }),
//             boxSizing: 'border-box',
//             ...(!open && {
//                 overflowX: 'hidden',
//                 transition: theme.transitions.create('width', {
//                     easing: theme.transitions.easing.sharp,
//                     duration: theme.transitions.duration.leavingScreen,
//                 }),
//                 width: theme.spacing(7),
//                 [theme.breakpoints.up('sm')]: {
//                     width: theme.spacing(9),
//                 },
//             }),
//         },
//     }),
// );

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const riseUpTheme = createTheme({
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

//{accountInfo}
export default function Home({accountInfo}) {
    // console.log(accountInfo.jwtIdToken)
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <ThemeProvider theme={riseUpTheme}>
            <Box sx={{display: 'flex', minHeight: '100vh'}}>
                <CssBaseline/>
                <Box component="nav" sx={{width: '100%', flexShrink: {sm: 0}}}>
                    <Drawer variant="permanent"   sx={{
                        '& .MuiDrawer-paper': {
                            backgroundColor: '#E4E1DC',
                        },
                    }}>
                        <Toolbar/>
                    </Drawer>
                    <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                        <Header/>
                        <Box
                            component="main"
                            sx={{
                                backgroundColor: 'white',
                                flexGrow: 1,
                                // height: '100vh',
                                overflow: 'auto',
                            }}
                        >
                            <Toolbar/>
                            <Container sx={{mt: 8,  width: '100%', paddingBottom: '3%'}}>
                            <Forms accountInfo={accountInfo}/>
                            {/*<Routes>*/ }
                            {/*    <Route path="/" element={<Forms />}/>*/}
                            {/*    <Route path="/report" element={<Report  />}/>*/}
                            {/*</Routes>*/}
                            <Box sx={{bgcolor: 'background.paper', width: '100%'}}>
                                <Typography align="center" variant="h5" sx={{fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#2B6C82"}}>
                                    Effective Recognition Steps
                                </Typography>
                                <Box
                                    component="img"
                                    sx={{width: '100%', height: '210px'}}
                                    alt="riseUpGif"
                                    src={riseUpGif}
                                />
                            </Box>
                            <Box sx={{bgcolor: 'background.paper', width: '100%', paddingBottom: '20px'}}>
                                <Stack direction="row" spacing={2} sx={{marginLeft: "20px"}}>
                                    {/*<Button sx={{*/}
                                    {/*    textTransform: 'none',*/}
                                    {/*    backgroundColor: "white",*/}
                                    {/*    color: "black",*/}
                                    {/*    width: '30%',*/}
                                    {/*    fontSize: '12px'*/}
                                    {/*}} startIcon={<img src={miniRiseUpLogo} alt="Rise Up Logo"/>}*/}
                                    {/*        href="">*/}
                                    {/*    <Link href={`/report/RiseUp`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', cursor: 'default' }}>*/}
                                    {/*        View Recent Rise Ups*/}
                                    {/*    </Link>*/}
                                    {/*</Button>*/}
                                    <Link href={`/report/RiseUp`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', display: 'inline-block', width: '40%' }}>
                                        <Button sx={{
                                            textTransform: 'none',
                                            backgroundColor: "white",
                                            color: "black",
                                            width: '100%',
                                            fontSize: '12px'
                                        }} startIcon={<img src={miniRiseUpLogo} alt="Rise Up Logo"/>}>
                                            View Recent Rise Ups
                                        </Button>
                                    </Link>
                                    <Link href={`/report/PremierExperience`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', display: 'inline-block', width: '40%' }}>
                                        <Button sx={{
                                            textTransform: 'none',
                                            backgroundColor: "white",
                                            color: "black",
                                            width: '100%',
                                            fontSize: '12px'
                                        }} startIcon={<img src={miniRiseUpLogo} alt="Rise Up Logo"/>}>
                                            View Recent Premier Experiences
                                        </Button>
                                    </Link>
                                    {/*<Button sx={{*/}
                                    {/*    textTransform: 'none',*/}
                                    {/*    backgroundColor: "white",*/}
                                    {/*    color: "black",*/}
                                    {/*    width: '40%',*/}
                                    {/*    fontSize: '12px'*/}
                                    {/*}} startIcon={<img src={miniRiseUpLogo} alt="Rise Up Logo"/>}>*/}
                                    {/*    <Link href={`/report/PremierExperience`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', cursor: 'default' }}>*/}
                                    {/*        View Recent Premier Experiences*/}
                                    {/*    </Link>*/}
                                    {/*</Button>*/}
                                    {/*<Button sx={{*/}
                                    {/*    textTransform: 'none',*/}
                                    {/*    backgroundColor: "white",*/}
                                    {/*    color: "black",*/}
                                    {/*    width: '40%',*/}
                                    {/*    fontSize: '12px'*/}
                                    {/*}} startIcon={<img src={miniRiseUpLogo} alt="Rise Up Logo"/>}>*/}
                                    {/*    <Link href={`/report/ServiceRecovery`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', cursor: 'default' }}>*/}
                                    {/*        View Recent Service Recoveries*/}
                                    {/*    </Link>*/}
                                    {/*</Button>*/}
                                    <Link href={`/report/ServiceRecovery`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', display: 'inline-block', width: '40%' }}>
                                        <Button sx={{
                                            textTransform: 'none',
                                            backgroundColor: "white",
                                            color: "black",
                                            width: '100%',
                                            fontSize: '12px'
                                        }} startIcon={<img src={miniRiseUpLogo} alt="Rise Up Logo"/>}>
                                            View Recent Service Recoveries
                                        </Button>
                                    </Link>
                                    <Button sx={{
                                        textTransform: 'none',
                                        backgroundColor: "white",
                                        color: "black",
                                        width: '40%',
                                        fontSize: '12px'
                                    }} startIcon={<img src={workbookLogo} alt="Workbook Logo"/>}
                                            href="https://choicefinancial.sharepoint.com/sites/HumanResources/Choice%20University/Forms/AllItems.aspx?id=%2Fsites%2FHumanResources%2FChoice%20University%2FHandouts%2FPremier%20Experience%20Workbook%2Epdf&parent=%2Fsites%2FHumanResources%2FChoice%20University%2FHandouts" target="_blank">Open
                                        Premier Experience Workbook</Button>
                                </Stack>
                            </Box>
                            <Box sx={{bgcolor: 'background.paper', width: '100%'}}>
                                <Typography align="center" variant="h6" sx={{fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#2B6C82"}}>
                                    Questions? Need Rise Up Help?
                                </Typography>
                                <Typography align="center" variant="subtitle1" sx={{color: "black", fontFamily: 'Segoe UI'}}>
                                    Reach out to our Rise Up Page Champion:&nbsp;
                                    <Link href="mailto:k.jensen@bankwithchoice.com" target="_blank" variant="subtitle1" sx={{color: "black", fontFamily: 'Segoe UI', fontWeight: 'bold'}}>Kärsten Jensen.</Link>
                                </Typography>
                            </Box>
                            </Container>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}