
export function createRiseUp() {
    const RiseUp = {
        id: "",
        riseupType: "",
        recipientName: "",
        createdBy: "",
        created: "",
        nominatorLocation: "",
        location: "",
        print: "",
        accountOfficer: "",
        title: "",
        complianceAssetId: "",
        customerAccount: "",
        situation: "",
        experience: "",
        giftAmount: "",
        strengthsShoutOut: "",
        noFollowUpRequired: false,
        officerFollowUpRequired: false,
        recipientType: "",
        whoElseHelped: "",
        department: "",
        modified: "",
        flagAsTopStory: "",
        dateTime: "",
        contentType: "",
        modifiedBy: "",
        attachments: "",
        itemChildCount: "",
        folderChildCount: "",
        labelSetting: "",
        retentionLabel: "",
        retentionLabelApplied: "",
        labelAppliedBy: "",
        item_is_a_record: "",
        createdByEmail: "",
        culturalMindset: ""
    };

    return RiseUp;
}
