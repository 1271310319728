import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {Alert, AlertTitle, TextField} from "@mui/material";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RiseUpLogo from '../../assets/images/rise-up-form-logo.png';
import Box from "@mui/material/Box";
import {useState, useEffect} from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from '@mui/material/styles';
import PopupWindow from "../PopupWIndow";
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import strengthIcon from '../../assets/icons/strengths-icon.png';
import teamIcon from '../../assets/icons/team-member-lookup.png';
import riseUpIcon from '../../assets/icons/rise-up-story.png';
import culturalMindsetIcon from '../../assets/icons/cultural-mindsets.png';
import cultrualMindsetList from "../../data/cultrualMindset";
import { createRiseUp } from "../../Object/RiseUp";
import config from "../../config/config";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    border: 'none !important',
    boxShadow: 'none',
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const StyledButton = styled(Button)({
    borderColor: '#00BBCC',
    border: '2px solid',
    color: '#00BBCC',
    '&:hover': {
        backgroundColor: '#00BBCC',
        borderColor: '#00BBCC',
        border: '2px solid',
        color: 'white',
    },
    '&:active': {
        backgroundColor: '#00BBCC',
        borderColor: '#00BBCC',
        border: '2px solid',
        color: 'white',
    },
});

export default function RiseUpForm({accountInfo, employeeList}) {
    // const [employeeList, setEmployeeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [strengthList, setStrengthList] = useState([]);
    const [strength, setStrength] = useState([]);
    const [showStrength, setShowStrength] = useState(false);
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [culturalMindset, setCulturalMindset] = useState("");
    const [riseUp, setRiseUp] = React.useState(createRiseUp());
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const authToken = "Bearer " + accountInfo.jwtIdToken;
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setStrength(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const handleEmployeeSelect = (event, newValue) => {
        setEmployees(newValue);
    };

    const handleCulturalMindsetSelect = (event, newValue) => {
        const {
            target: { value },
        } = event;
        setCulturalMindset(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    useEffect(() => {
        if (loading) {
            const submitData = async () => {
                try{
                    const response = await fetch(config.BAST_URL + "riseup/submit", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `${authToken}`
                        },
                        body: JSON.stringify(riseUp)
                    });

                    if (response.ok) {
                        console.log('Success:', response);
                        setLoading(false);
                        handleOpen();
                    } else {
                        setLoading(false);
                        console.error('Error:', response);
                        setShowAlert(true);
                        setErrorMessage('Error while submitting Rise Up: ' + response.status + ' ' + response.statusText );
                    }
                } catch (error) {
                    setLoading(false);
                    console.error('There was an error!', error);
                    setShowAlert(true);
                    setErrorMessage('Error while submitting Rise Up: ' + error );
                }
            };

            submitData();
        }
    }, [loading, riseUp]);

    useEffect(() =>{
        if (employees.length === 1) {
            setShowStrength(true);
            setStrengthList(employeeList.filter(employee => employee.employeeName === employees[0])[0]?.strengths);
        } else {
            setShowStrength(false);
        }
    }, [employees]);

    const submitForm = async (e) => {
        e.preventDefault();
        if (employees.length === 0) {
            setShowAlert(true);
            setErrorMessage("TEAM MEMBER cannot be empty.")
        } else {
            setLoading(true);
            let  createdBy = employeeList.filter(employee => employee.email.trim().toLowerCase() === accountInfo.account.userName.toLowerCase())[0];
            let recipient = employeeList.filter(employee => employee.employeeName.trim() === employees[0])[0];
            setRiseUp(prevState => ({
                ...prevState,
                riseupType: "RiseUp",
                recipientName: employees.join("\n"),
                createdBy: accountInfo.account.name,
                createdByEmail: accountInfo.account.userName,
                strengthsShoutOut: strength ? strength.join(", ") : '',
                nominatorLocation: createdBy ? createdBy.location : '',
                culturalMindset: culturalMindset ? culturalMindset.join(", ") : '',
                department: recipient.department,
                location: recipient.location
            }));
            console.log(riseUp);
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <PopupWindow open={open} handleClose={handleClose} formType={"rise-up"} />
            {showAlert && (
                <Alert severity="error" onClose={() => {setShowAlert(false);}}>
                    <AlertTitle>Something went wrong</AlertTitle>
                    {errorMessage}
                </Alert>
            )}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '30%',  marginRight: '10%'}}>
                <Box
                    component="img"
                    sx={{ height:'200px', marginTop: '8%'}}
                    alt="Logo"
                    src={RiseUpLogo}
                />
                    <Typography variant="h5" gutterBottom sx={{ color: "#00b4db", fontFamily: 'Segoe UI', fontWeight: 'semibold', marginTop: '10%'}}>
                        Tell us about the
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ color: "#00b4db", fontFamily: 'Segoe UI', fontWeight: 'semibold'}}>
                        awesome thing your
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ color: "#00b4db", fontFamily: 'Segoe UI', fontWeight: 'semibold'}}>
                        teammate just did!
                    </Typography>
                </div>
            <Stack spacing={2} sx={{ width: '60%'}}>
                <Item>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Box
                            component="img"
                            sx={{ height:'26px', marginBottom: '4px'}}
                            alt="Logo"
                            src={teamIcon}
                        />
                    <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px' }}>
                        TEAM MEMBER
                    </Typography>
                    </div>
                    <Autocomplete
                        multiple
                        disablePortal
                        required
                        id="employee-select"
                        // options={employeeList}
                        options={employeeList.map((option) => option.employeeName)}
                        sx={{ width: '80%' }}
                        value={employees}
                        onChange={handleEmployeeSelect}
                        renderInput={(params) => <TextField {...params} label="Find team members" />}
                    />
                </Item>
                {
                    showStrength && (
                        <Item>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: ''}}>
                                {/*<ThumbUpAltIcon sx={{ fontSize: '26px',color: "orange", marginBottom: '4px'}}/>*/}
                                <Box
                                    component="img"
                                    sx={{ height:'26px', marginBottom: '4px'}}
                                    alt="Logo"
                                    src={strengthIcon}
                                />
                                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px' }}>
                                    STRENGTHS SHOUT OUT
                                </Typography>
                            </div>
                            <FormControl sx={{ m: 0, width: '80%' }}>
                                <InputLabel id="demo-multiple-name-label">Strength</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    value={strength}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Strength" />}
                                    MenuProps={MenuProps}
                                >
                                    {strengthList.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            style={getStyles(name, strength, theme)}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Item>
                    )
                }

                <Item>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: ''}}>
                        <Box
                            component="img"
                            sx={{ height:'26px', marginTop: '2px'}}
                            alt="Logo"
                            src={riseUpIcon}
                        />
                    <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px' }}>
                        SHARE AWESOME
                    </Typography>
                    </div>
                    <TextField
                        id="outlined-multiline-static"
                        label="Tell us a story…"
                        multiline
                        rows={4}
                        sx={{ width: '80%' }}
                        value={riseUp.situation}
                        onChange={(e) => setRiseUp({ ...riseUp, situation: e.target.value })}
                    />
                </Item>
                <Item>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: ''}}>
                        {/*<ThumbUpAltIcon sx={{ fontSize: '26px',color: "orange", marginBottom: '4px'}}/>*/}
                        <Box
                            component="img"
                            sx={{ height:'26px', marginTop: '2px'}}
                            alt="Logo"
                            src={culturalMindsetIcon}
                        />
                        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px' }}>
                            CULTURAL MINDSET
                        </Typography>
                    </div>
                    <FormControl sx={{ m: 0, width: '80%' }}>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={culturalMindset}
                            onChange={handleCulturalMindsetSelect}
                        >
                            {cultrualMindsetList.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Item>
            </Stack>
            </div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
                <StyledButton variant="outlined"  onClick={submitForm} sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold' }}>
                    Submit Rise Up
                </StyledButton>
            </Box>
        </React.Fragment>
    );
}