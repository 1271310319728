
const local = {
    BAST_URL: "https://riseup-service-dev.bankwithchoice.tech/riseup-service-dev/",
    REDIRECT_URL: "https://localhost:3000",
    PORT_NUMBER: 8080,
}

const dev = {
    BAST_URL: "https://riseup-service-dev.bankwithchoice.tech/riseup-service-dev/",
    REDIRECT_URL: "https://riseup-dev.bankwithchoice.tech",
    PORT_NUMBER: 80,
}

const qa = {
    BAST_URL: "https://riseup-service-qa.bankwithchoice.tech/riseup-service-qa/",
    REDIRECT_URL: "https://riseup-qa.bankwithchoice.tech",
    PORT_NUMBER: 80,
}

const prod = {
    BAST_URL: "https://riseup-service.bankwithchoice.tech/riseup-service/",
    REDIRECT_URL: "https://riseup.bankwithchoice.tech",
    PORT_NUMBER: 80,
}

const hostname = window && window.location && window.location.hostname;
let config = local;
if (hostname.indexOf("riseup-dev.bankwithchoice.tech") >= 0) {
    console.log("app deployed in dev");
    config = dev;
} else if (hostname.indexOf("riseup-qa.bankwithchoice.tech") >= 0) {
    console.log("app deployed in qa");
    config = qa;
} else if (hostname.indexOf("riseup.bankwithchoice.tech") >= 0) {
    console.log("app deployed in prod");
    config = prod;
} else if (hostname.indexOf("localhost") >= 0) {
    console.log("app deployed in local");
    config = local;
} else {
        console.log("exporting dev config");
        config = dev;
}

export default config;