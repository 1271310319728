import ServiceRecorveryForm from "../ServiceRecorvery";
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import RiseUpForm from '../RiseUpForm';
import PremierExperienceForm from "../PremierExperienceForm";
import Box from "@mui/material/Box";
import {AppBar, Button, Stack} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import * as React from "react";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import {useTheme} from "@mui/material/styles";
import riseUpGif from "../../assets/images/ChoiceCulture_EffectiveRecogntion_Step_Animation.gif";
import miniRiseUpLogo from "../../assets/images/rise-up-logo-mini.png";
import workbookLogo from "../../assets/images/rise-up-workbook-logo.png";
import Link from "@mui/material/Link";
import Container from '@mui/material/Container';
import config from "../../config/config";
import CircularProgress from "@mui/material/CircularProgress";
// import { Link } from 'react-router-dom';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

//{accountInfo}
export default function Forms({accountInfo}) {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [employeeList, setEmployeeList] = useState([]);
    const [loading, setLoading] = useState(true);
    const authToken = "Bearer " + accountInfo.jwtIdToken;
    useEffect(() => {
        fetch(config.BAST_URL + 'employee/all', {
            method: 'GET',
            headers: {
                'Authorization': `${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setEmployeeList(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setLoading(false);
            });
    }, []);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };


    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress/>
            </Box>
        );
    }
    return (
        // <Container sx={{mt: 8,  width: '100%'}}>
        <Box sx={{bgcolor: 'white', width: '100%'}}>
            <AppBar position="static" elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="white"
                    // textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    sx = {{backgroundColor: "white"}}
                >
                    <Tab
                        label="RISE UP"
                        {...a11yProps(0)}
                        style={{marginRight: '10px', fontFamily: 'Segoe UI', fontSize: '1vw', fontWeight: 'bold', ...value === 0 ? {backgroundColor: '#00BBCB', color: 'white'} : {border: '2px solid', backgroundColor: 'white', color: '#00BBCB'}}}
                    />
                    <Tab
                        label="PREMIER EXPERIENCE"
                        {...a11yProps(1)}
                        style={{marginRight: '10px', fontFamily: 'Segoe UI', fontSize: '1vw', fontWeight: 'bold', ...value === 1 ? {backgroundColor: '#6CB444', color: 'white'} : {border: '2px solid', backgroundColor: 'white', color: '#6CB444'}}}
                    />
                    <Tab
                        label="SERVICE RECOVERY"
                        {...a11yProps(2)}
                        style={{fontFamily: 'Segoe UI', fontWeight: 'bold', fontSize: '1vw', ...value === 2 ? {backgroundColor: '#F37043', color: 'white'} : {border: '2px solid', backgroundColor: 'white', color: '#F37043'}}}
                    />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                        <RiseUpForm accountInfo={accountInfo} employeeList={employeeList}/>
                    </Paper>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                        <PremierExperienceForm accountInfo={accountInfo} employeeList={employeeList}/>
                    </Paper>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                        <ServiceRecorveryForm accountInfo={accountInfo} employeeList={employeeList}/>
                    </Paper>
                </TabPanel>
            </SwipeableViews>
        </Box>

        // </Container>
    );

}