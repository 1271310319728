import {
    Box,
    Paper,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import HighFive from '../../assets/images/high-five.png';
import * as React from "react";
import Link from '@mui/material/Link';
import {styled} from "@mui/system";

export default function PopupWindow({open, handleClose, formType}) {
    let buttonColor = formType === "rise-up" ? "#00BBCC" : formType === "premier-experience" ? "#6CB444" : formType === "service-recovery" ? "#F37042" : "#00BBCC";
    const StyledButton = styled(Button)({
        borderColor: buttonColor,
        color: buttonColor,
        backgroundColor: '#FDFDFD',
        '&:hover': {
            backgroundColor: buttonColor,
            borderColor: buttonColor,
            color: 'white',
        },
        '&:active': {
            backgroundColor: buttonColor,
            borderColor: buttonColor,
            color: 'white',
        },
    });
    const handleRefresh = () => {
        window.location.reload();
    }
    return (
        <div>
            <Dialog fullWidth open={open} onClose={handleClose} >
                {/*<AppBar position="relative" open={open} style={{ background: 'linear-gradient(to left, #00b4db, #0083b0, #FFC107, #4CAF50)' }}>*/}
                {/*    <Toolbar >*/}
                {/*        <Box*/}
                {/*            component="img"*/}
                {/*            sx={{ height: 40, display: 'flex', justifyContent: 'flex-end',}}*/}
                {/*            alt="Logo"*/}
                {/*            src={riseUpText}*/}
                {/*        />*/}
                {/*    </Toolbar>*/}
                {/*</AppBar>*/}
                <Paper square={false} sx={{backgroundColor: '#92D4D8', p: 2, display: 'flex', flexDirection: 'column', height: '280px'}}>
                    {/*<DialogTitle sx={{ color: "white", fontFamily: 'Segoe UI', fontWeight: 'semibold' }}>Thanks for sharing awesome!</DialogTitle>*/}
                    <DialogContent>
                        <Box sx={{display: 'flex', flexDirection: 'row', paddingTop: '15px', marginBottom: '20px', paddingLeft: '30px',}}>
                            <Box
                                component="img"
                                sx={{ height: '100px', marginTop: '20px' }}
                                alt="Logo"
                                src={HighFive}
                            />
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // alignItems: 'center',
                                paddingLeft: '20px',
                                marginTop: '20px'
                            }}>
                                <DialogContentText
                                    sx={{color: "white", fontFamily: 'Segoe UI', fontWeight: '600', fontSize: '18px'}}>
                                    Thanks for sharing awesome!
                                </DialogContentText>
                                <DialogContentText
                                    sx={{color: "black", fontFamily: 'Segoe UI', fontWeight: '600', fontSize: '12px'}}>
                                    Got more stories to tell?
                                </DialogContentText>
                                {
                                    formType === "rise-up" && (
                                        <StyledButton variant="outlined" onClick={handleRefresh} sx={{ fontFamily: 'Segoe UI', fontWeight: '600', marginTop: '30px' }}>
                                            Submit Another Rise Up
                                        </StyledButton>

                                    )
                                }
                                {
                                    formType === "premier-experience" && (
                                        <StyledButton variant="outlined" onClick={handleRefresh} sx={{ fontFamily: 'Segoe UI', fontWeight: '600', marginTop: '30px' }}>
                                            Submit Another Premier Experience
                                        </StyledButton>

                                    )
                                }
                                {
                                    formType === "service-recovery" && (
                                        <StyledButton variant="outlined" onClick={handleRefresh} sx={{ fontFamily: 'Segoe UI', fontWeight: '600', marginTop: '30px' }}>
                                            Submit Another Service Recovery
                                        </StyledButton>

                                    )
                                }
                                <DialogContentText sx={{ marginTop: '10px'}}>
                                    <Link href="https://choicefinancial.sharepoint.com/sites/TheVault" target="_blank" sx={{color: "white", fontFamily: 'Segoe UI', fontWeight: '600', fontSize: '12px'}}>Go to The Vault Home ></Link>
                                </DialogContentText>
                            </Box>
                        </Box>
                    </DialogContent>
                    {/*<DialogActions>*/}
                    {/*    <Button onClick={handleClose}>Close</Button>*/}
                    {/*</DialogActions>*/}
                </Paper>
            </Dialog>
        </div>
    )
}