import Box from "@mui/material/Box";
import RiseUpLogo from "../../assets/images/rise-up-form-logo.png";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import PremierExperienceImg from "../../assets/images/PremierExperience_MakingPeopleFirstMemories_01.png";


export default function PrintPremierExperience({rowData}) {

    return (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10vh' }}>
            <Box
                component="img"
                sx={{
                    height: '300px',
                    paddingRight: '20px',
                    marginTop: '20vh',
                    '@media print': {
                        marginTop: '10vh',
                        paddingRight: '1vw',
                        '@media (orientation: portrait)': {
                            marginTop: '20vh',
                            height: '20vh',
                        },
                        '@media (orientation: landscape)': {
                            marginTop: '20vh',
                            height: '20vw',
                        }
                    }
                }}
                alt="Logo"
                src={PremierExperienceImg}
            />
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.5vw' }, '@media (orientation: portrait)': {fontSize: '1.5vh' } } }}>
                    ACCOUNT OFFICER
                </Typography>
                <Typography sx={{ fontFamily: 'Segoe UI', marginLeft: '8px' , '@media print': { '@media (orientation: landscape)': {fontSize: '1.2vw' }, '@media (orientation: portrait)': {fontSize: '1.2vh' } }}}>
                    {rowData.accountOfficer ? rowData.accountOfficer : "None"}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.5vw' }, '@media (orientation: portrait)': {fontSize: '1.5vh' } } }}>
                    CUSTOMER ACCOUNT
                </Typography>
                <Typography sx={{ fontFamily: 'Segoe UI', marginLeft: '8px' , '@media print': { '@media (orientation: landscape)': {fontSize: '1.2vw' }, '@media (orientation: portrait)': {fontSize: '1.2vh' } }}}>
                    {rowData.customerAccount ? rowData.customerAccount : "None"}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.5vw' }, '@media (orientation: portrait)': {fontSize: '1.5vh' } } }}>
                    RECIPIENT NAME
                </Typography>
                <Typography sx={{ fontFamily: 'Segoe UI', marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.2vw' }, '@media (orientation: portrait)': {fontSize: '1.2vh' } } }}>
                    {rowData.recipientName ? rowData.recipientName : "None"}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.5vw' }, '@media (orientation: portrait)': {fontSize: '1.5vh' } } }}>
                    RECIPIENT TYPE
                </Typography>
                <Typography sx={{ fontFamily: 'Segoe UI', marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.2vw' }, '@media (orientation: portrait)': {fontSize: '1.2vh' } } }}>
                    {rowData.recipientType ? rowData.recipientType : "None"}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.5vw' }, '@media (orientation: portrait)': {fontSize: '1.5vh' } } }}>
                    SITUATION/DISCOVERY
                </Typography>
                <Typography sx={{ fontFamily: 'Segoe UI', marginLeft: '8px' , '@media print': { '@media (orientation: landscape)': {fontSize: '1.2vw' }, '@media (orientation: portrait)': {fontSize: '1.2vh' } }}}>
                    {rowData.situation ?
                        <div style={{whiteSpace: 'pre-line'}}>
                            {rowData.situation}
                        </div>
                        : "none"
                    }
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.5vw' }, '@media (orientation: portrait)': {fontSize: '1.5vh' } } }}>
                    EXPERIENCE/RECOVERY
                </Typography>
                <Typography sx={{ fontFamily: 'Segoe UI', marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.2vw' }, '@media (orientation: portrait)': {fontSize: '1.2vh' } } }}>
                    {rowData.experience ?
                        <div style={{whiteSpace: 'pre-line'}}>
                            {rowData.experience}
                        </div>
                        : "none"
                    }
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.5vw' }, '@media (orientation: portrait)': {fontSize: '1.5vh' } } }}>
                    GIFT AMOUNT (IF APPLICABLE)
                </Typography>
                <Typography sx={{ fontFamily: 'Segoe UI', marginLeft: '8px' , '@media print': { '@media (orientation: landscape)': {fontSize: '1.2vw' }, '@media (orientation: portrait)': {fontSize: '1.2vh' } }}}>
                    {rowData.giftAmount ? rowData.giftAmount : "None"}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.5vw' }, '@media (orientation: portrait)': {fontSize: '1.5vh' } } }}>
                    FOLLOW UP
                </Typography>
                <Typography sx={{ fontFamily: 'Segoe UI', marginLeft: '8px' , '@media print': { '@media (orientation: landscape)': {fontSize: '1.2vw' }, '@media (orientation: portrait)': {fontSize: '1.2vh' } }}}>
                    {rowData.officerFollowUpRequired ? "Yes" : "No"}
                </Typography>
            </div>
        </div>
    )
}