import Box from "@mui/material/Box";
import RiseUpLogo from "../../assets/images/rise-up-form-logo.png";
import * as React from "react";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/system";
import Paper from "@mui/material/Paper";
import './print.css';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function PrintRiseUp({ rowData }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10vh' }}>
            <Box
                component="img"
                sx={{
                    height: '250px',
                    paddingRight: '20px',
                    marginTop: '20vh',
                    '@media print': {
                        paddingRight: '1vw',
                        '@media (orientation: portrait)': {
                            marginTop: '20vh',
                            height: '15vh',
                        },
                        '@media (orientation: landscape)': {
                            marginTop: '20vh',
                            height: '20vh',
                        }
                    }
                }}
                alt="Logo"
                src={RiseUpLogo}
            />
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.5vw' }, '@media (orientation: portrait)': {fontSize: '1.5vh' } } }}>
                    TEAM MEMBER
                </Typography>
                <Typography sx={{ fontFamily: 'Segoe UI', marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.2vw' }, '@media (orientation: portrait)': {fontSize: '1.2vh' } } }}>
                    {rowData.recipientName ? rowData.recipientName : "None"}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.5vw' }, '@media (orientation: portrait)': {fontSize: '1.5vh' } } }}>
                    STRENGTHS SHOUT OUT
                </Typography>
                <Typography sx={{ fontFamily: 'Segoe UI', marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.2vw' }, '@media (orientation: portrait)': {fontSize: '1.2vh' } } }}>
                    {rowData.strengthsShoutOut ? rowData.strengthsShoutOut : "N/A"}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: "#F37043", marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.5vw' }, '@media (orientation: portrait)': {fontSize: '1.5vh' } } }}>
                    SITUATION/DISCOVERY
                </Typography>
                <Typography sx={{ fontFamily: 'Segoe UI', marginLeft: '8px', '@media print': { '@media (orientation: landscape)': {fontSize: '1.2vw' }, '@media (orientation: portrait)': {fontSize: '1.2vh' } } }}>
                    {rowData.situation ?
                        <div style={{whiteSpace: 'pre-line'}}>
                        {rowData.situation}
                        </div>
                        : "none"
                    }
                </Typography>
            </div>
        </div>
    );
}