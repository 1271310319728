import './App.css';
import React from "react";
import Home from './components/Home'
import {Route, Routes} from "react-router-dom";
import Report from './components/Report'
import Forms from "./components/Forms";
import { authProvider } from './authProvider';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
const App = () => (
    <AzureAD provider={authProvider} forceLogin={true}>
        {
            ({login, logout, authenticationState, error, accountInfo}) => {
                switch (authenticationState) {
                    case AuthenticationState.Authenticated:
                        return (
                            <Routes>
                                <Route path="/" element={<Home accountInfo={accountInfo}/>}/>
                                <Route path="/report/:type" element={<Report  accountInfo={accountInfo}/>}/>
                            </Routes>
                        );
                    case AuthenticationState.Unauthenticated:
                        return (
                            <div>
                                {error && <p><span>An error occured during authentication, please try again!</span></p>}
                                <p>
                                    <span>Hey stranger, you look new!</span>
                                    <button onClick={login}>Login</button>
                                </p>
                            </div>
                        );
                    case AuthenticationState.InProgress:
                        return (<p>Authenticating...</p>);
                    default:
                        return (<p>Authenticating...</p>);
                }
            }
        }
    </AzureAD>
    // <Routes>
    //     <Route path="/" element={<Home />}/>
    //     <Route path="/report/:type" element={<Report />}/>
    // </Routes>
);

export default App;