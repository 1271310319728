import {
    Box,
    Paper,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    useMediaQuery, useTheme,
    Tooltip,
    DialogActions
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import riseUpColorBar from "../../assets/images/rise-up-color-bar.jpg";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import PrintRiseUp from './PrintRiseUp';
import PrintPremierExperience from "./PrintPremierExperience";
import PrintServiceRecovery from "./PrintServiceRecovery";
import ReactToPrint from 'react-to-print';
import {useRef} from "react";
import './print.css';
import Typography from "@mui/material/Typography";
export default function PrintForm({open, handleClose, rowData}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
    let componentRef = useRef();
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    return (
        <div >
            <Dialog                 fullScreen
                                     open={open} maxWidth="xl" onClose={handleClose} ref={componentRef}
            >

                <DialogContent         sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    '@media print': {
                        height: '100vh',
                    }
                }}>

                    <AppBar
                        position="static"
                        elevation={0}
                        sx={{
                            height: 36,
                            backgroundImage: `url(${riseUpColorBar})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: '#FFFFFF',
                            zIndex: 1301,
                            '@media print': {
                                backgroundSize: 'contain',
                            },
                            '@media print and (orientation: portrait)': {
                                height: '5vh',
                            },
                            '@media print and (orientation: landscape)': {
                                height: '5vh',
                            }
                        }}
                    >
                        <Toolbar>

                        </Toolbar>
                    </AppBar>
                    <Box sx={{ flex: '1 0 auto' }}>
                    {
                        rowData.riseupType === "RiseUp" && (
                                <PrintRiseUp rowData={rowData} />
                        )
                    }
                    {
                        rowData.riseupType === "PremierExperience" && (
                            <PrintPremierExperience rowData={rowData} />
                        )
                    }
                    {
                        rowData.riseupType === "ServiceRecovery" && (
                            <PrintServiceRecovery rowData={rowData} />
                        )
                    }
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '5px',
                            '@media print': {
                                paddingTop: '5vh',
                                marginBottom: '1vh',
                            }

                        }}
                    >
                        <Typography variant="subtitle2" gutterBottom sx={{ fontFamily: 'Segoe UI', '@media print': { fontSize: '1.2vw' }}}>
                            Created by {rowData.createdBy} at {formatDate(rowData.created)}
                        </Typography>
                    </Box>
                    <DialogActions  sx={{display: 'flex', flexDirection: 'row',
                        '@media print': {
                            display: 'none',
                        }}}>
                        <ReactToPrint
                            trigger={() =>
                                <Tooltip title="Print">
                                    <Button variant="contained" color="primary">
                                        Print
                                    </Button>
                                </Tooltip>
                            }
                            content={() => componentRef.current}
                        />
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                    <AppBar
                        position="static"
                        elevation={0}
                        sx={{
                            height: 36,
                            backgroundImage: `url(${riseUpColorBar})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: '#FFFFFF',
                            zIndex: 1301,
                            '@media print': {
                                backgroundSize: 'contain',
                            },
                            '@media print and (orientation: portrait)': {
                                height: '5vh',
                            },
                            '@media print and (orientation: landscape)': {
                                height: '5vh',
                            }
                        }}
                    >
                        <Toolbar>

                        </Toolbar>
                    </AppBar>
                </DialogContent>
            </Dialog>
        </div>
    )
}