import React, {useEffect, useState} from 'react';
import {
    DataGridPro,
    getGridStringOperators,
    getGridDateOperators,
    getGridNumericOperators,
    GridRenderCellParams,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport, GridToolbarExportContainer, GridCsvExportMenuItem
} from '@mui/x-data-grid-pro';
import config from "../../config/config";
import {LicenseInfo} from '@mui/x-license-pro';
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Header from "../Header";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Button from '@mui/material/Button';
import PrintForm from '../PrintForm';
import { useParams } from 'react-router-dom';
import getFilterString from "./FilterConverter";
import Typography from "@mui/material/Typography";
import { useGridApiContext } from '@mui/x-data-grid-pro';
import * as XLSX from 'xlsx';
import MenuItem from "@mui/material/MenuItem";
LicenseInfo.setLicenseKey('c530cbff6c54ad3a8f982142521f3370Tz05NTc3NixFPTE3NTQ2Nzk0NjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==');


function CustomExportButton({fileName}) {
    const csvOptions = { fileName: fileName };

    const apiRef = useGridApiContext();
    const data = [];
    const columnMapping = {
        customerAccount: 'Customer Account',
        recipientName: 'Recipient Name',
        accountOfficer: 'Relationship Manager',
        recipientType: 'Recipient Type',
        department: fileName === 'RiseUp' ? 'Department' : 'Relationship Manager Department',
        location: fileName === 'RiseUp' ? 'Location' : 'Relationship Manager Location',
        createdBy: 'Created By',
        nominatorLocation: fileName === 'RiseUp' ? 'Nominator Location' : 'Submitter Location',
        created: 'Created Date',
        strengthsShoutOut: 'Strengths Shout Out',
        culturalMindset: 'Cultural Mindset',
        situation: fileName === 'RiseUp' ? 'Story' : 'Situation/Discovery',
        experience: fileName === 'PremierExperience' ? 'Premier Experience' : 'Service Recovery',
        giftAmount: 'Gift Amount(If Applicable)',
        whoElseHelped: 'Who else made this possible',
        officerFollowUpRequired: 'Follow Up Required',
    };
    const handleExportExcel = () => {
        const rows = apiRef.current.getRowModels();
        const data = [];
        rows.forEach((row) => {
            console.log(row);
            let  {
                id,
                riseupType,
                createdByEmail,
                item_is_a_record,
                labelAppliedBy,
                retentionLabelApplied,
                retentionLabel,
                labelSetting,
                folderChildCount,
                itemChildCount,
                attachments,
                modifiedBy,
                contentType,
                flagAsTopStory,
                modified,
                print,
                noFollowUpRequired,
                title,
                complianceAssetId,
                ...filteredRow
            } = row;
            if (fileName === "RiseUp") {
                const { accountOfficer, customerAccount, experience, giftAmount, officerFollowUpRequired, recipientType, whoElseHelped, ...furtherFilteredRow } = filteredRow; // Exclude additional columns
                filteredRow = furtherFilteredRow;
            } else if (fileName === "PremierExperience") {
                const { strengthsShoutOut, culturalMindset, officerFollowUpRequired,  ...furtherFilteredRow } = filteredRow; // Exclude additional columns
                filteredRow = furtherFilteredRow;
            } else {
                const { strengthsShoutOut, culturalMindset,  ...furtherFilteredRow } = filteredRow; // Exclude additional columns
                filteredRow = furtherFilteredRow;
            }
            const newRow = {};
            for (const key in filteredRow) {
                if (columnMapping[key]) {
                    if (key === 'created') {
                        newRow[columnMapping[key]] = new Date(filteredRow[key]);
                    } else {
                        newRow[columnMapping[key]] = filteredRow[key];
                    }
                } else {
                    newRow[key] = filteredRow[key];
                }
            }
            data.push(newRow);
        });

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };
    return (
        <GridToolbarExportContainer>
            <GridCsvExportMenuItem options={csvOptions} />
            {/*<MenuItem onClick={handleExportExcel}>*/}
            {/*    Download as Excel*/}
            {/*</MenuItem>*/}
        </GridToolbarExportContainer>
    );
}
function CustomToolbar({fileName}) {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton/>
            <CustomExportButton fileName={fileName}/>
        </GridToolbarContainer>
        // <GridToolbarContainer>
        //     <GridToolbarFilterButton/>
        //     <GridToolbarExport
        //         csvOptions={{ fileName: fileName }}
        //         // printOptions={{
        //         //     allColumns: true,
        //         //     hideFooter: true,
        //         //     hideToolbar: true,
        //         // }}
        //         printOptions={{ disableToolbarButton: true }}
        //     />
        //     <button >Download as Excel</button>
        // </GridToolbarContainer>
    );
}
//{accountInfo}
const Report = ({accountInfo}) => {
    const defaultFilterModel =[{ id: 'created', field: 'created', operator: 'is', value: '' }];
    const { type } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [filter, setFilter] = useState(defaultFilterModel);
    const [gridHeight, setGridHeight] = useState(window.innerHeight - 150);
    const [open, setOpen] = React.useState(false);
    const [rowData, setRowData] = React.useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const authToken = "Bearer " + accountInfo.jwtIdToken;

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const usdPrice = {
        type: 'number',
        width: 130,
        valueFormatter: (value) => {
            if (value == null) {
                return ''; // Return empty string for null or undefined values
            }
            return currencyFormatter.format(value);
        },
        // cellClassName: 'font-tabular-nums',
    };

    const stringOperators = getGridStringOperators().filter((op => ['equals','contains', 'startsWith', 'endsWith'].includes(op.value)));
    const dateOperators = getGridDateOperators().filter((op => ['is', 'before', 'after', 'onOrBefore', 'onOrAfter'].includes(op.value)));
    const numberOperators = getGridNumericOperators().filter((op => ['=', '>', '<', '>=', '<='].includes(op.value)));


    let titleColor = type === "RiseUp" ? "#00BBCC" : type === "PremierExperience" ? "#6CB444" : type === "ServiceRecovery" ? "#F37042" : "#00BBCC";
    let title = type === "RiseUp" ? "Rise Ups" : type === "PremierExperience" ? "Premier Experiences" : type === "ServiceRecovery" ? "Service Recoveries" : "";

    const handleFilterModelChange = (model) => {
        let filters = [{
            id: 'created',
            field: 'created',
            operator: 'is',
            value: '',
        }];
        if (model.items.length === 0) {
            setFilter(defaultFilterModel);
        } else {
            filters = model.items.map((item) => ({
                    id: item.field,
                    field: item.field,
                    operator: item.operator,
                    value: item.value,
                })
            );
            if (filters[0].field !== '' && filters[0].value !== undefined){
                setShowAlert(false);
                setFilter(filters);
            }
        }
        if(isFiltersValid(filters)){
            fetchDataWithFiltersAndPagination(filters, page);
        }
    };

    const handlePageChange = (newPage) => {
        console.log(newPage);
        setPage(newPage);
    };

    const isFiltersValid = (filters) => {
        return !filters.some(filter => filter.value === undefined);
    };

    const handleClickOpen = (params) => {
        setRowData(params.row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const riseUpTheme = createTheme({
        palette: {
            primary: {
                main: '#1976d2',
            },
        },
        typography: {
            h5: {
                fontWeight: 500,
                fontSize: 26,
                letterSpacing: 0.5,
            },
        },
        shape: {
            borderRadius: 8,
        },
        components: {
            MuiTab: {
                defaultProps: {
                    disableRipple: true,
                },
            },
        },
        mixins: {
            toolbar: {
                minHeight: 48,
            },
        },
    });

    useEffect(() =>{
        fetchDataWithFiltersAndPagination(filter, page);
    }, [page]);


    useEffect(() => {
        let url = `riseup/report?page=0&filters=riseupType=` + type;

        const fetchData = async () => {
            try {
                const response = await fetch(config.BAST_URL + url , {
                    method: 'GET',
                    headers: {
                        'Authorization': `${authToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                } else {
                    const result = await response.json();
                    let filteredData = [];
                    if(result.length !== 0 && result.totalElements !==0) {
                        filteredData = result.data;
                        setTotalRows(result.totalElements);
                    } else {
                        setTotalRows(0);
                    }
                    setData(filteredData);
                    setShowAlert(false);
                    setLoading(false);
                }
            } catch (error) {
                console.error('There was an error!', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchDataWithFiltersAndPagination = async (filters, currentPage) => {
        try {
            setLoading(true);
            let page = 0;
            if (typeof currentPage.page !== 'undefined') {
                page = currentPage.page;
            }
            let url = `riseup/report` + `?page=` + page + `&filters=riseupType=` + type;
            console.log(filters);
            if(filters){
                const filter_str = getFilterString(filters);
                url += `,` + filter_str;
            } else{
                setFilter();
            }
            const response = await fetch(config.BAST_URL + url,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${authToken}`
                    }
                });
            if (response.ok) {
                const result = await response.json();
                let filteredData = [];
                if(result.length !== 0 && result.totalElements !==0) {
                    filteredData = result.data;
                    setTotalRows(result.totalElements);
                } else {
                    setTotalRows(0);
                }
                setData(filteredData);
                setShowAlert(false);
                setLoading(false);
            }  else {
                setData([]);
                setLoading(false);
                setShowAlert(true);
                setErrorMessage('Error while fetching filtered data, error code: ' + response.status);
                console.error('Error filtering data: ', response);
            }
        } catch (error) {
            setData([]);
            setLoading(false);
            setShowAlert(true);
            setErrorMessage('Error while fetching filtered data: ' + error);
            console.error('Error while fetching filtered data: ', error);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setGridHeight(window.innerHeight - 150);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const columns = [
        {
            field: 'print',
            headerName: 'print',
            sortable: false,
            width: 100,
            disableClickEventBubbling: true,
            filterable: false,
            disableExport: true,
            renderCell: (params) => (
                <Button color="primary" onClick={() => handleClickOpen(params)}>
                    Print
                </Button>
            ),
        },
        // {field: 'riseupType', headerName: 'Rise Up Type', width: 150, filterable: false},

        {field: 'customerAccount', headerName: 'Customer Account', width: 200, filterOperators: stringOperators, filterable: type !== 'RiseUp'},
        {
            field: 'recipientName',
            headerName: 'Recipient Name',
            width: 200,
            filterOperators: stringOperators,
            renderCell: (params: GridRenderCellParams) => (
                <div style={{whiteSpace: 'pre-line'}}>
                    {params.value}
                </div>
            ),
        },
        {field: 'recipientType', headerName: 'Recipient Type', width: 150, filterOperators: stringOperators, filterable: type !== 'RiseUp', disableExport: type === 'RiseUp'},
        {field: 'accountOfficer', headerName: 'Relationship Manager', width: 150, filterOperators: stringOperators, filterable: type !== 'RiseUp', disableExport: type === 'RiseUp'},
        {field: 'department', headerName: type === 'RiseUp' ? 'Department' : 'Relationship Manager Department', width: 250, filterOperators: stringOperators},
        {field: 'location', headerName: type === 'RiseUp' ? 'Location' : 'Relationship Manager Location', width: 250, filterOperators: stringOperators},

        {field: 'createdBy', headerName: 'Created By', width: 150, filterOperators: stringOperators},
        {field: 'nominatorLocation', headerName: type === 'RiseUp' ? 'Nominator Location' : 'Submitter Location', width: 200, filterOperators: stringOperators},
        {
            field: 'created',
            headerName: 'Created Date',
            width: 250,
            type: 'date',
            filterOperators: dateOperators,
            valueGetter: (value) => {
                return new Date(value);
            },
        },
        {field: 'strengthsShoutOut', headerName: 'Strengths Shout Out', width: 200, filterable: type === 'RiseUp', disableExport: type !== 'RiseUp'},
        {field: 'culturalMindset', headerName: 'Cultural Mindset', width: 150, filterable: type === 'RiseUp', disableExport: type !== 'RiseUp'},
        {field: 'situation', headerName: type === 'RiseUp' ? 'Story' : 'Situation/Discovery', width: 450,
            renderCell: (params: GridRenderCellParams) => (
                <div style={{whiteSpace: 'pre-line'}}>
                    {params.value}
                </div>
            ),
            filterable: false,},
        {field: 'experience', headerName: type === 'PremierExperience' ? 'Premier Experience' : 'Service Recovery', width: 400,
            renderCell: (params: GridRenderCellParams) => (
                <div style={{whiteSpace: 'pre-line'}}>
                    {params.value}
                </div>
            ),
            filterable: false},
        {field: 'giftAmount', headerName: 'Gift Amount(If Applicable)', width: 205, filterOperators: numberOperators, 
            valueFormatter: (value) => {
                if (value == null) {
                    return '';
                }
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(value);
            },
            filterable: type !== 'RiseUp',
            disableExport: type === 'RiseUp'
        },
        {field: 'whoElseHelped', headerName: 'Who else made this possible', width: 200, filterable: false,},
        {field: 'officerFollowUpRequired', headerName: 'Follow Up Required', type: 'boolean', width: 200, filterable: type === 'ServiceRecovery', disableExport: type !== 'ServiceRecovery'},
        {field: 'id', headerName: 'ID', width: 90},
        {field: 'complianceAssetId', headerName: 'Compliance Asset ID', width: 200, filterable: false, disableExport: true,},


        // { field: 'modified', headerName: 'Modified', width: 150 },
        // { field: 'steven', headerName: 'Steven', width: 100 },
        // { field: 'flagAsTopStory', headerName: 'Flag As Top Story', width: 200 },
        // { field: 'contentType', headerName: 'Content Type', width: 150 },
        // { field: 'modifiedBy', headerName: 'Modified By', width: 150 },
        // { field: 'attachments', headerName: 'Attachments', width: 150 },
        // { field: 'itemChildCount', headerName: 'Item Child Count', width: 200 },
        // { field: 'folderChildCount', headerName: 'Folder Child Count', width: 200 },
        // { field: 'labelSetting', headerName: 'Label Setting', width: 150 },
        // { field: 'retentionLabel', headerName: 'Retention Label', width: 150 },
        // { field: 'retentionLabelApplied', headerName: 'Retention Label Applied', width: 200 },
        // { field: 'labelAppliedBy', headerName: 'Label Applied By', width: 150 },
        // { field: 'item_is_a_record', headerName: 'Item Is A Record', width: 150 }
    ];


    // if (loading) {
    //     return (
    //         <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
    //             <CircularProgress/>
    //         </Box>
    //     );
    // }

    return (
        <ThemeProvider theme={riseUpTheme}>
            <Box sx={{display: 'flex', minHeight: '100vh'}}>
                <CssBaseline/>
                <PrintForm open={open} handleClose={handleClose} rowData={rowData}/>
                <Box component="nav" sx={{width: '100%', flexShrink: {sm: 0}}}>
                    <Drawer variant="permanent" sx={{
                        '& .MuiDrawer-paper': {
                            backgroundColor: '#E4E1DC',
                        },
                    }}>
                        <Toolbar/>
                    </Drawer>
                    <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                        <Header/>
                        <Box sx={{height: gridHeight, width: '100%', marginTop: '20px'}}>
                            <Typography variant="h5" gutterBottom sx={{color: titleColor, fontFamily: 'Segoe UI', fontWeight: 'semibold',marginLeft: '120px'}}>
                                {title}
                            </Typography>
                            <DataGridPro
                                getRowHeight={() => 'auto'}
                                rows={data} columns={columns}
                                pagination
                                checkboxSelection
                                pageSizeOptions={[1000]}
                                rowCount={totalRows}
                                paginationMode="server"
                                onPaginationModelChange={handlePageChange}
                                filterMode="server"
                                onFilterModelChange={handleFilterModelChange}
                                loading={loading}
                                sx={{marginLeft: '120px', marginRight: '40px'}}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false,
                                            dateTime: false,
                                            complianceAssetId: false,
                                            customerAccount: type !== 'RiseUp',
                                            experience: type !== 'RiseUp',
                                            giftAmount: type !== 'RiseUp',
                                            officerFollowUpRequired: type === 'ServiceRecovery',
                                            recipientType: type !== 'RiseUp',
                                            accountOfficer: type !== 'RiseUp',
                                            whoElseHelped: type === 'PremierExperience',
                                            strengthsShoutOut: type === 'RiseUp',
                                            culturalMindset: type === 'RiseUp'
                                        }
                                    },
                                    filter: {
                                        filterModel: {
                                            items: [{ id: 'created', field: 'created', operator: 'is', value: undefined }],
                                        },
                                    },
                                    pagination: { paginationModel: { pageSize: 1000 } },
                                }}
                                slots={{
                                    toolbar: (props) => <CustomToolbar {...props} fileName={type} />,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Report;